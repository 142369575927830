<template>
  <div>
    <iframe
      :src="frame"
      width="100%"
      height="1200px"
      frameborder="0"
    ></iframe>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { jsPDF } from 'jspdf'
import { useRouter } from '@core/utils'
import prints from '@/api/prints'
import { i18n } from '@/plugins/i18n'
import font from '@/assets/fonts/font.json'
import fontBold from '@/assets/fonts/fontBold.json'
import { formatPrice, sumdatetime, sumdate } from '@/plugins/filters'
import 'jspdf-autotable'

export default {
  setup() {
    const { route } = useRouter()
    const fonts = ref(font)
    const fontsBold = ref(fontBold)
    const dataTableList = ref([])
    const fontTitle = ref(14)
    const fontBody = ref(13)
    const frame = ref('')

    i18n.locale = route.value.query.lang == '2' ? 'en' : 'th'
    prints.printsDrugTransferOut({
      transfer_id_pri: route.value.query.id.split(','),
      lang: i18n.locale,
    }).then(res => {
      savePDF(res)
    })

    const headers = [
      i18n.t('code'),
      i18n.t('list'),
      i18n.t('expire_date'),
      i18n.t('amount'),
      i18n.t('unit'),
      i18n.t('price_unit'),
      i18n.t('amount_money'),
    ]
    const generateData = data => {
      const result = []
      data.forEach(item => {
        result.push([
          item.transferorder_drug_id,
          item.transferorder_drug_name,
          sumdate(item.transferorder_drugorder_expdate),
          formatPrice(item.transferorder_amount),
          item.transferorder_drug_unit,
          formatPrice(item.transferorder_price),
          formatPrice(item.transferorder_total),
        ])
      })

      return result
    }
    const savePDF = data => {
      const doc = new jsPDF({
        orientation: 'portrait',
        format: 'a4',
      })
      doc.setProperties({
        title: 'ใบโอนยา / อุปกรณ์',
      })
      doc.addFileToVFS('TH-Niramit-AS-normal.ttf', fonts.value.data)
      doc.addFileToVFS('TH-Niramit-AS-Bold-bold.ttf', fontsBold.value.data)
      doc.addFont('TH-Niramit-AS-normal.ttf', 'TH-Niramit', 'normal')
      doc.addFont('TH-Niramit-AS-Bold-bold.ttf', 'TH-Niramit', 'bold')
      doc.setFont('TH-Niramit', 'bold')
      const spacer = 5
      let total = 10

      for (let i = 0; i < data.length; i++) {
        const footer = [
          {
            content: 'รวม',
            colSpan: 6,
            styles: {
              halign: 'center',
            },
          },
          formatPrice(data[i].transfer_total),
        ]
        dataTableList.value = data[i].check_list
        doc.addImage(JSON.parse(localStorage.getItem('shopImage')), 'JPEG', 7, 6, 28, 26)

        // Start Address Shop block\
        doc.setFontSize(fontTitle.value)
        doc.setFillColor('#212121')
        doc.text(`${data[i].shop_name} ${data[i].shop_nature}`, 40, total)
        doc.setFont('TH-Niramit', 'normal')
        total += spacer
        doc.text(`${data[i].shop_address} ${data[i].shop_district}`, 40, total)
        total += spacer
        doc.setFontSize(16)
        doc.text('ใบโอนยา / อุปกรณ์', 205, total - 5, null, null, 'right')
        doc.text(`${i18n.t('a_number')} ${data[i].transfer_id}`, 205, total + 2, null, null, 'right')
        doc.setFontSize(fontTitle.value)
        doc.text(`${data[i].shop_amphoe} ${data[i].shop_province} ${data[i].shop_zipcode}`, 40, total)
        total += spacer
        doc.text(`เบอร์โทรศัพท์ ${data[i].shop_tel} ${i18n.t('email')} ${data[i].shop_email}`, 40, total)
        total += spacer
        doc.text(`${i18n.t('numberLicenses')} ${data[i].shop_license} ${i18n.t('tax_id')} ${data[i].shop_tax}`, 40, total)
        total += spacer
        doc.setLineWidth(0.8)
        doc.setDrawColor('#1E88E5')
        doc.line(61, 34, 205, 34)
        doc.setDrawColor('#212121')
        doc.line(4, 34, 60, 34)
        total += spacer
        doc.setFontSize(fontTitle.value)
        doc.text('โอนไปยัง : ', 5, total)
        doc.text(`${data[i].shop_id_name_to}`, 21, total)
        total += spacer
        doc.text('ที่อยู่ : ', 5, total)
        doc.text(`${data[i].shop_address_to} ${data[i].shop_amphoe_to} ${data[i].shop_district_to} ${data[i].shop_province_to} ${data[i].shop_zipcode_to}`, 15, total)
        total += spacer
        doc.text('เบอร์โทรศัพท์ : ', 5, total)
        doc.text(`${data[i].shop_tel_to}`, 29, total)
        total += spacer * 2
        doc.setFont('TH-Niramit', 'bold')
        doc.text(`${i18n.t('list')}`, 5, total)
        doc.setFont('TH-Niramit', 'normal')
        doc.text(`${i18n.t('date')} : ${sumdatetime(data[i].transfer_create)}`, 205, total, null, null, 'right')

        doc.autoTable({
          theme: 'grid',
          styles: {
            font: 'TH-Niramit',
            fontStyle: 'normal',
            textColor: '#212121',
            fontSize: fontBody.value,
            halign: 'left',
          },
          showFoot: 'lastPage',
          headStyles: {
            textColor: '#fff',
            fillColor: '#1E88E5',
            fontStyle: 'bold',
            lineWidth: 0.1,
            fontSize: fontTitle.value,
            halign: 'center',
          },
          footStyles: {
            textColor: '#212121',
            fillColor: '#fff',
            fontStyle: 'bold',
            lineWidth: 0.1,
            fontSize: fontBody.value,
            halign: 'right',
          },
          columnStyles: {
            0: { halign: 'center', cellWidth: 18 },
            1: { halign: 'left', fillColor: '#F5F5F5' },
            2: { halign: 'center', cellWidth: 24 },
            3: { halign: 'right', fillColor: '#F5F5F5', cellWidth: 15 },
            4: { halign: 'center', cellWidth: 15 },
            5: { halign: 'right', fillColor: '#F5F5F5', cellWidth: 25 },
            6: { halign: 'right', cellWidth: 25 },
          },
          startY: 61,
          margin: { left: 5, right: 5, bottom: 10 },
          head: [headers],
          body: generateData(data[i].transferorder),
          foot: [footer],

          didDrawPage(datax) {
            total = datax.cursor.y
          },
        })
        total += spacer
        doc.setFont('TH-Niramit', 'bold')
        doc.text('หมายเหตุ : ', 5, total)
        doc.setFont('TH-Niramit', 'normal')
        doc.text(data[i].transfer_comment || '', 23, total)
        total += spacer * 2
        doc.text('....................................................................', 52.5, total, null, null, 'center')
        doc.text('....................................................................', 157.5, total, null, null, 'center')
        total += spacer
        doc.text(`(${data[i].user_fullname})`, 52.5, total, null, null, 'center')
        doc.text('(                                    )', 157.5, total, null, null, 'center')
        total += spacer
        doc.text(`${i18n.t('date')} ${sumdate(data[i].transfer_create)}`, 52.5, total, null, null, 'center')
        doc.text(`${i18n.t('date')}............./............./.............`, 157.5, total, null, null, 'center')
      }

      frame.value = doc.output('datauristring', { filename: 'test.pdf' })
    }

    return { savePDF, frame }
  },
}
</script>
